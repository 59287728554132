import { GAvatar } from '@/design-system/g-avatar';
import { GLoader } from '@/design-system/g-loader';
import Button from '@/design-system/v3/button';
import { deleteAttendeeFromCourse, FETCH_COURSE_DETAIL_QUERY, FETCH_COURSE_MEMBERS_QUERY } from '@/web/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, PageWrapper } from '../../page-wrapper';
import { BackToButton } from '@/web/views/extra-menu';
import { getCourseDetailPath } from '@/web/routes';
import { NoResultsFound } from '../../no-results-found';
import { useTranslation } from 'react-i18next';
import OneFitIcon from '@/icons/one-fit.svg?react';
import ClassPassIcon from '@/icons/classpass.svg?react';
import HourglassIcon from '@/icons/hourglass.svg?react';
import { MEMBER_ACTIVE_STATUSES, useCourseDetail, useCourseMembers } from '@/web/views/course-detail';
import { CourseDetailMember, CourseDetailMemberType, CourseMemberStatus } from '@/web/types';
import { Badge, BadgeVariant } from '@/design-system/v3/badge';
import clsx from 'clsx';
import { CourseOptionsMenu, MenuMode } from '@/web/views/course-detail/course-options-menu';
import PeopleAddIcon from '@/icons/people-add.svg?react';
import TrashIcon from '@/icons/trash.svg?react';
import { useSetRecoilState } from 'recoil';
import { panelState } from '@/common/atoms';
import { handleValidationError, highlightQuery, parseCourseISO } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import { add, isPast } from 'date-fns';
import { GuestType } from '@/features/guest/enums/GuestType.enum';

const alwaysAllowedCheckinTypes = ['ADMIN', 'STAFF'];
const allowedCheckinTypes = ['ADMIN', 'STAFF', 'TEACHER'];

export const AttendeesListView = () => {
  const { t } = useTranslation();
  const { businessUser } = useBusiness();
  const { course } = useCourseDetail();
  const navigate = useNavigate();
  const { uuid, startAt } = useParams();
  const { allMembers, members, isFetching } = useCourseMembers();
  const setPanel = useSetRecoilState(panelState);
  const [showAddMemberMenu, setShowAddMemberMenu] = useState<boolean>(false);

  const canCheckinAttendees = useMemo(() => {
    if (!course || !businessUser) {
      return false;
    }

    if (isPast(add(course.endAtDate, { minutes: 30 }))) {
      return alwaysAllowedCheckinTypes.includes(businessUser.type);
    }

    return allowedCheckinTypes.includes(businessUser.type);
  }, [businessUser, course]);

  const handleRemove = (member: CourseDetailMember) => async () => {
    if (!course) {
      return;
    }
    setPanel({
      component: RemoveConfirmationModal,
      props: {
        courseId: course.id,
        courseDate: parseCourseISO(course.startAt, course),
        member,
        onClose: () => setPanel(null),
      },
      isOpen: true,
      autoHeight: true,
    });
  };

  const handleAddMember = () => {
    setShowAddMemberMenu(true);
  };

  const content = isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <GLoader variant="secondary" />
    </div>
  ) : allMembers.length === 0 ? (
    <div className="flex flex-col items-center justify-center h-contentHeight">
      <NoResultsFound label={t('attendeesView.noResults.title')} />
    </div>
  ) : (
    <div className="flex flex-col justify-between min-h-full">
      <div>
        {allMembers.map((member) => (
          <div
            key={member.id}
            className={clsx('w-full p-4 border-b border-b-borders-secondary', {
              'bg-error-25': member.status === CourseMemberStatus.NO_SHOW,
              'bg-gray-25': member.status === CourseMemberStatus.WAITING,
              'bg-warning-25 opacity-60': member.status === CourseMemberStatus.LATE_CANCELLED,
              'bg-gray-25 opacity-60': member.status === CourseMemberStatus.CANCELLED,
            })}
          >
            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex flex-row items-center space-x-2">
                <>
                  {canCheckinAttendees && member.externalProviderId === 'FITOGRAM' && (
                    <GAvatar
                      key={member.id}
                      size="2xs"
                      className="first:ml-0 -ml-px"
                      style={{
                        boxShadow: '0 0 0 2px white',
                      }}
                    >
                      <OneFitIcon className="absolute w-full h-full object-cover rounded-full" />
                    </GAvatar>
                  )}
                  {canCheckinAttendees && member.externalProviderId === 'CLASS_PASS' && (
                    <GAvatar
                      key={member.id}
                      size="2xs"
                      className="first:ml-0 -ml-px"
                      style={{
                        boxShadow: '0 0 0 2px white',
                      }}
                    >
                      <ClassPassIcon className="absolute w-full h-full object-cover rounded-full" />
                    </GAvatar>
                  )}
                  {(!canCheckinAttendees || !member.externalProviderId) && (
                    <GAvatar
                      key={member.id}
                      letter={member.firstName?.charAt(0) || ''}
                      size="2xs"
                      className="first:ml-0 -ml-px"
                      style={{
                        boxShadow: '0 0 0 2px white',
                      }}
                      path={member?.profileImageUrl}
                    />
                  )}
                </>
                <div className="flex items-center justify-between w-full text-left mr-2">
                  <div className="flex flex-col">
                    <div
                      className={clsx({
                        'break-all': member.birthday,
                      })}
                    >
                      {member.type === CourseDetailMemberType.MEMBER &&
                        (member.fullName || member.firstName || t('anonymousMember'))}
                      {member.type === CourseDetailMemberType.EXTERNAL &&
                        (member.fullName || member.firstName || t('anonymous'))}
                      {member.type === CourseDetailMemberType.GUEST &&
                        (member.fullName || member.firstName || t('anonymous'))}
                      {member.type === CourseDetailMemberType.GUEST &&
                        (member.fullName || member.firstName) &&
                        ` (${t('guest')})`}
                    </div>
                    {member.status === CourseMemberStatus.ATTENDED && canCheckinAttendees && (
                      <span className="text-success-600">{t('attending')}</span>
                    )}
                    {member.status === CourseMemberStatus.NO_SHOW && canCheckinAttendees && (
                      <span className="text-error-600">{t('noShow')}</span>
                    )}
                    {member.status === CourseMemberStatus.WAITING && canCheckinAttendees && (
                      <span className="text-gray-600">{t('activityCard.isWaiting.text')}</span>
                    )}
                    {member.status === CourseMemberStatus.CANCELLED && canCheckinAttendees && (
                      <span className="text-gray-600">{t('cancelled')}</span>
                    )}
                    {member.status === CourseMemberStatus.LATE_CANCELLED && canCheckinAttendees && (
                      <span className="text-warning-600">{t('lateCancelled')}</span>
                    )}
                  </div>
                  {member.birthday && (
                    <Badge variant={BadgeVariant.SUCCESS} className="mx-2 px-3" size="small">
                      <span className="inline-flex mr-2">🎉</span> {t('birthday')}
                    </Badge>
                  )}
                  {member.lead && member.guest && member.guest.type === GuestType.TRIAL && (
                    <Badge
                      variant={BadgeVariant.SUCCESS}
                      className="inline-flex space-x-1 mx-2 p-0.5 px-1"
                      size="small"
                    >
                      <HourglassIcon className="inline-flex" /> <span className="pr-1">T{member.lead.trials}</span>
                    </Badge>
                  )}
                </div>
              </div>
              {canCheckinAttendees && MEMBER_ACTIVE_STATUSES.includes(member.status) && (
                <div className="flex items-center justify-end">
                  <Button variant="secondary" className="text-typo-negative" onClick={handleRemove(member)}>
                    <TrashIcon className="h-6 w-6" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <PageWrapper
      header={
        <PageHeader
          leftAction={uuid && startAt ? <BackToButton path={getCourseDetailPath(uuid, startAt, '')} /> : null}
          title={`${t('attendeesView.title')} ${course ? `(${members.length}/${course.capacity})` : ''}`}
          rightAction={
            <>
              {canCheckinAttendees && (
                <button onClick={handleAddMember} className="text-secondary">
                  <PeopleAddIcon className="h-6 w-6" />
                </button>
              )}
            </>
          }
        />
      }
      footerClasses="px-4"
      footer={
        <div>
          {canCheckinAttendees && (
            <Button
              className="mt-4"
              onClick={() => {
                if (course) {
                  navigate(getCourseDetailPath(course.id, course.startAt, 'checkin'));
                }
              }}
            >
              {t('checkIn')}
            </Button>
          )}
        </div>
      }
      content={
        <>
          {course && canCheckinAttendees && (
            <CourseOptionsMenu
              course={course}
              open={showAddMemberMenu}
              onClose={() => setShowAddMemberMenu(false)}
              initialMode={MenuMode.ADD_MEMBER}
            />
          )}
          {content}
        </>
      }
      contentClasses="p-0"
      embedHeader
      embedFooter
    />
  );
};

const RemoveConfirmationModal = ({
  courseId,
  courseDate,
  member,
  onClose,
}: {
  courseId: string;
  courseDate: Date;
  member: CourseDetailMember;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { errorMessage, successMessage } = useSnack();

  const { mutate, isLoading } = useMutation(async () => {
    try {
      await deleteAttendeeFromCourse(courseId, courseDate.toISOString(), member.id);
    } catch (e) {
      handleValidationError(e, undefined, errorMessage);
      return;
    }

    successMessage(t('attendeesView.snack.removeSuccess.title'), {
      detailedMessage: t('attendeesView.snack.removeSuccess.message', { name: member.fullName }),
    });
    onClose();

    await Promise.all([
      queryClient.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY, courseId]),
      queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, courseId]),
    ]);
  });

  return (
    <div className="flex flex-col space-y-4">
      <span>
        {highlightQuery(
          t('activityAttendees.removeMember.warning', {
            name: member.fullName,
          }),
          member.fullName,
        )}
      </span>

      <div className="flex flex-col space-y-2">
        <Button variant="secondary" className="text-typo-negative" onClick={mutate} loading={isLoading}>
          {t('remove')}
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};
