import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { IconProps } from 'phosphor-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export enum BadgeVariant {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface BadgeProps extends React.HTMLAttributes<any> {
  variant?: BadgeVariant;
  size?: 'small' | 'medium';
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
}

export const Badge = ({
  children,
  icon,
  size = 'small',
  variant = BadgeVariant.DEFAULT,
  className,
}: React.PropsWithChildren<BadgeProps>) => {
  const classes = clsx('inline-flex items-center text-sm rounded-full border', {
    'p-1': size === 'small',
    'px-3 py-1': size === 'medium',
    'bg-gray-100 text-gray-700 border-gray-200': variant === BadgeVariant.DEFAULT,
    'bg-success-50 text-success-700 border-success-200': variant === BadgeVariant.SUCCESS,
    'bg-warning-50 text-warning-700 border-warning-200': variant === BadgeVariant.WARNING,
    'bg-error-50 text-error-700 border-error-200': variant === BadgeVariant.ERROR,
  });

  const iconClasses = clsx('mr-1', {
    'text-gray-500': variant === BadgeVariant.DEFAULT,
    'text-success-500': variant === BadgeVariant.SUCCESS,
    'text-warning-500': variant === BadgeVariant.WARNING,
    'text-error-500': variant === BadgeVariant.ERROR,
  });

  const Icon = icon;

  return (
    <span className={twMerge(classes, className)}>
      {Icon && <Icon className={iconClasses} />}
      {children}
    </span>
  );
};
