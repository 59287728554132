import { CourseDetailMember, CourseDetailWithHelpers, CourseMemberStatus, RoomLocation } from '@/web/types';
import { GAvatar } from '@/design-system/g-avatar';
import { Title } from '@/design-system/v3/title';
import { formatWithT } from '@/i18n/utils';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCourseDetailPath } from '@/web/routes';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { AddToCalendarButton, CourseDetailsJoinStatus } from '../course-actions';
import { Markup } from '../../markup';
import { useCourseMembers } from '@/web/views/course-detail';
import { SkeletonLoading } from '@/common/components/booking-flow/course-card';
import RoomIcon from '@/common/components/icon/icons/v2/room.svg?react';
import MarkerPinIcon from '@/common/components/icon/icons/v2/marker-pin.svg?react';
import ClockIcon from '@/common/components/icon/icons/v2/clock.svg?react';
import { useRooms } from '@/web/hooks/use-rooms';
import { BringAFriendActionButton } from '@/features/bring-a-friend/components/BringAFriendActionButton/BringAFriendActionButton';
import { useBusiness } from '@/web/hooks/use-business';
import { useEmbed } from '@/common/hooks/use-embed';

export const CourseDetailCard = ({ course }: { course: CourseDetailWithHelpers; isMember: boolean }) => {
  const { t } = useTranslation();
  const { businessFeatures } = useBusiness();
  const navigate = useNavigate();
  const rooms = useRooms();
  const embed = useEmbed();

  const { members, isFetching } = useCourseMembers();

  const getAttendeesText = () => {
    return t('activityActions.attendees.text', { count: course.memberCount });
  };

  const courseType = course.detailedActivities[0];
  const courseTypeImage = courseType?.imagePath;
  const courseTypeDescription = courseType?.description;
  const description = course?.description || courseTypeDescription;

  const isBringAFriendModuleActive = businessFeatures?.includes('BringAFriend');

  const room = useMemo(() => {
    return rooms.find((r) => r.id === course.roomId);
  }, [rooms, course]);

  return (
    <div>
      {courseTypeImage && <GAvatar src={courseTypeImage} className="rounded-none w-full h-[200px]" />}
      <div className="flex flex-col">
        <CourseDetailSection>
          <Title variant="title1" className="mb-1 text-[36px] font-median">
            {course.name}
          </Title>
          <div className="space-y-2">
            {course.teachers.length > 0 && (
              <div className="flex flex-row items-center space-x-2">
                <GAvatar path={course.teachers[0].profileImagePath || ''} size="2xs" />
                <div className="flex flex-col text-typo-secondary">{course.teachers[0].fullName}</div>
              </div>
            )}
            <CourseDetailsJoinStatus withSpots={false} />
            {!!description?.length && (
              <div>
                <Markup className="py-2" content={description} trimTo={130} />
              </div>
            )}
            {members && members.length > 0 && (
              <button
                className="self-start w-full mt-1"
                onClick={() => {
                  if (course.id && course.startAt)
                    navigate(getCourseDetailPath(course.id, course.startAt, 'attendees'));
                }}
              >
                <div className="flex items-center space-x-2 text-sm">
                  {isFetching ? (
                    <>
                      <div className="flex items-center -space-x-2">
                        <SkeletonLoading className="h-6 w-6 rounded-xl" />
                        <SkeletonLoading className="h-6 w-6 rounded-xl" />
                        <SkeletonLoading className="h-6 w-6 rounded-xl" />
                        <SkeletonLoading className="h-6 w-6 rounded-xl" />
                        <SkeletonLoading className="h-6 w-6 rounded-xl" />
                      </div>
                      <SkeletonLoading className="h-4 w-[40%] md:w-[10%]" />
                    </>
                  ) : (
                    <>
                      <div className="flex -space-x-2">
                        {members.slice(0, 5).map((member: CourseDetailMember) => (
                          <GAvatar
                            key={member.id}
                            size="2xs"
                            className="first:ml-0 -ml-px"
                            style={{
                              boxShadow: '0 0 0 2px white',
                            }}
                            path={member?.profileImageUrl}
                          />
                        ))}
                      </div>
                      <span className="text-secondary">{getAttendeesText()}</span>
                    </>
                  )}
                </div>
              </button>
            )}
            {isBringAFriendModuleActive && !course.isPast && <BringAFriendActionButton />}
            {!embed && <AddToCalendarButton />}
          </div>
        </CourseDetailSection>
        <CourseDetailSection>
          <Title variant="title3" className="mb-4 font-median">
            {t('activityDetails.details.title')}
          </Title>
          <div className="flex flex-col space-y-4 text-typo-primary">
            <div className="flex items-center space-x-2 text-base">
              <MarkerPinIcon className="h-5 w-5" />
              <div>{course.businessLocation.name}</div>
            </div>
            <div className="flex items-center space-x-2 text-base">
              <ClockIcon className="h-5 w-5" />
              <div>
                {formatWithT(course.startAtDate, 'd MMM, HH:mm')} - {formatWithT(course.endAtDate, 'HH:mm')}
              </div>
            </div>
            {room && (
              <div className="flex items-center space-x-2 text-base">
                <div>
                  <RoomIcon className="h-5 w-5" />
                </div>
                <div>
                  {room.location === RoomLocation.LOCATION && room.name}
                  {room.location === RoomLocation.OFFSIDE && room.offsideAddress && (
                    <>
                      <div>{room.offsideAddress.street}</div>
                      <div>
                        {room.offsideAddress.postalCode} {room.offsideAddress.city}
                      </div>
                    </>
                  )}
                  {room.location === RoomLocation.ONLINE && t('activityDetails.details.online')}
                </div>
              </div>
            )}
          </div>
        </CourseDetailSection>
      </div>
    </div>
  );
};

export const CourseDetailSection = ({ className, children }: { className?: string; children: ReactNode }) => (
  <section
    className={twMerge('px-4 py-5 border-b-[4px] border-b-borders-tertiary last:mb-5 last:border-b-0', className)}
  >
    {children}
  </section>
);
