import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useQuery } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import { GLoader } from '@/design-system/g-loader';
import { getCourseTypes } from '@/web/endpoints';
import { CourseTypeItem } from '@/web/views/management/manage-courses-view/course-type-item';
import { Paginator } from '@/common/components/paginator';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackToButton } from '../../extra-menu';
import { PROFILE_PATH } from '@/web/routes';

export const FETCH_COURSE_TYPES = 'FETCH_COURSE_TYPES';

export const ManageCoursesView = () => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const [page, setPage] = useState<number>(0);

  const { data, isLoading } = useQuery([FETCH_COURSE_TYPES, businessUuid, page], () => {
    if (businessUuid) {
      return getCourseTypes(businessUuid, page);
    }
  });

  const handleNextPage = () => setPage((prev) => prev + 1);
  const handlePrevPage = () => setPage((prev) => prev - 1);

  const canNextPage = Boolean(data && !data.last);
  const canPrevPage = Boolean(data && !data.first);

  return (
    <PageWrapper
      header={<PageHeader title={t('extraMenu.editActivities')} leftAction={<BackToButton path={PROFILE_PATH} />} />}
      content={
        <>
          {isLoading && <GLoader />}
          {data && (
            <div className="divide-y">
              {data.content.map((courseType) => (
                <CourseTypeItem key={courseType.id} courseType={courseType} />
              ))}
            </div>
          )}
          {data && (
            <Paginator
              onPreviousPage={handlePrevPage}
              onNextPage={handleNextPage}
              page={page + 1}
              totalPages={data.totalPages}
              canPreviousPage={canPrevPage}
              canNextPage={canNextPage}
              isFetching={isLoading}
            />
          )}
        </>
      }
    />
  );
};
