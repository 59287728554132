import { useBusiness } from '@/web/hooks/use-business';
import { CourseWithHelpers } from '@/web/types';
import clsx from 'clsx';
import { format, intervalToDuration, isAfter, isBefore } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { COURSES_PATH } from '@/web/routes';
import * as S from './styles';
import { GAvatar } from '@/design-system/g-avatar';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { GBoxedInfo } from '@/design-system/v3/g-boxed-info';
import { useQuery } from 'react-query';
import { getBusinessMember } from '@/business/endpoints';
import { Title } from '@/design-system/v3/title';
import { IconWrapper } from '../../icon-wrapper';
import ClockIcon from '@/common/components/icon/icons/v2/clock.svg?react';
import XCircleIcon from '@/common/components/icon/icons/v2/x-circle.svg?react';
import { CourseJoinSpots } from '../course-spots';
import { utcToZonedTime } from 'date-fns-tz';
import { CourseJoinStatus } from '../course-actions';
import { useTranslation } from 'react-i18next';
import { parseCourseISO } from '@/common/utils';

export enum CourseCardMode {
  DEFAULT,
  EXPANDED,
}

export const CourseCard = ({
  course,
  withDate,
  mode = CourseCardMode.DEFAULT,
  withLocation = false,
  withTeachers = true,
  withSpots = true,
  shouldScrollTo = false,
  withJoinStatus = true,
  cancelled = false,
  className,
}: {
  course: CourseWithHelpers;
  mode?: CourseCardMode;
  withSpots?: boolean;
  withDate?: boolean;
  withLocation?: boolean;
  withTeachers?: boolean;
  shouldScrollTo?: boolean;
  withJoinStatus?: boolean;
  cancelled?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  const { businessLocations } = useBusiness();
  const ref = useRef<HTMLAnchorElement>(null);
  const spots = course ? course.capacity - course.memberCount : 0;

  useEffect(() => {
    if (shouldScrollTo) {
      ref.current?.scrollIntoView();
    }
  }, [shouldScrollTo]);

  const isFull = spots <= 0;

  // Amount of days before this course starts.
  const now = new Date();
  const daysBefore = (parseCourseISO(course.startAt, course).getTime() - now.getTime()) / (1000 * 60 * 60 * 24);
  const maxDaysBefore = course.detailedActivities[0].options.subscribeDays ?? 7.0;

  const isAvailable = !course.isPast && daysBefore <= maxDaysBefore && !cancelled;

  return (
    <S.CourseCardWrapper
      ref={ref}
      className={twMerge(
        clsx(
          'group/course-card p-4 active:bg-gray-50 border-b border-b-borders-secondary last:border-b-0',
          isFull ? 'full-course' : 'available-course',
          {
            'w-full': mode === CourseCardMode.EXPANDED,
            'pointer-events-none': cancelled,
          },
        ),
        className,
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: isAvailable ? 1 : 0.5 }}
      exit={{ opacity: 0 }}
      key={course.uniqueUuid}
      to={`${COURSES_PATH}/${course.id}/${course.originalStartAt}`}
    >
      <div
        className={clsx('flex', {
          'flex-row space-x-4 items-center': mode === CourseCardMode.DEFAULT,
          'flex-col space-y-4 w-full': mode === CourseCardMode.EXPANDED,
        })}
      >
        {mode === CourseCardMode.EXPANDED && (
          <div className="flex h-[180px] w-full rounded-lg overflow-hidden">
            <img
              src={course.detailedActivities?.[0]?.imagePath || undefined}
              alt={course.name}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col">
            <Title as="h3" variant="title4" className="leading-none text-lg font-neutral">
              {course.name}
            </Title>
          </div>
          {withTeachers && course.teachers.length > 0 && (
            <div className="flex flex-row items-center space-x-2">
              <GAvatar path={course.teachers[0].profileImagePath || ''} size="2xs" />
              <div className="flex flex-col text-typo-primary">{course.teachers[0].fullName}</div>
            </div>
          )}
          <div className="flex flex-col text-xs space-y-2 text-gray-500">
            <div className="flex flex-row items-center space-x-[6px]">
              <CourseTime course={course} withDate={!!withDate} />
              {withSpots && (
                <GBoxedInfo
                  variant="default"
                  className="rounded-full px-3 py-0 min-h-[26px] flex font-medium items-center"
                >
                  <CourseJoinSpots spots={spots} capacity={course.capacity} />
                </GBoxedInfo>
              )}
            </div>
            {withJoinStatus && <CourseJoinStatus course={course} />}
            {cancelled && (
              <div className="flex flex-row space-x-1 text-error-500">
                <XCircleIcon /> <span>{t('cancelled')}</span>
              </div>
            )}
            {withLocation && (
              <GBoxedInfo>{businessLocations?.find((loc) => loc.id === course.businessLocationId)?.name}</GBoxedInfo>
            )}
          </div>
        </div>
      </div>
      {mode === CourseCardMode.DEFAULT && (
        <GAvatar
          src={course.detailedActivities?.[0]?.imagePath || undefined}
          className="rounded-xl w-[64px] h-[64px] min-w-[64px]"
        />
      )}
    </S.CourseCardWrapper>
  );
};

export const CourseTime = ({ course, withDate }: { course: CourseWithHelpers; withDate: boolean }) => {
  const { t } = useTranslation();
  const timezone = 'Europe/Amsterdam';
  const [currentTime, setCurrentTime] = useState(utcToZonedTime(new Date(), timezone));

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const zonedDate = utcToZonedTime(now, timezone);
      setCurrentTime(zonedDate);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (isAfter(currentTime, course.originalStartAtDate) && isBefore(currentTime, course.originalEndAtDate)) {
    const duration = intervalToDuration({ start: course.originalStartAtDate, end: currentTime });
    const hasHours = duration.hours && duration.hours > 0;
    const minutes = (duration?.minutes || 0).toString().padStart(2, '0');
    const seconds = (duration?.seconds || 0).toString().padStart(2, '0');

    const result = [];

    if (hasHours) {
      result.push(duration.hours);
    }

    result.push(minutes, seconds);

    const timerString = result.join(':');
    return (
      <GBoxedInfo
        variant="warning"
        className="rounded-full px-3 py-0 min-h-[26px] font-medium flex items-center uppercase"
      >
        {t('activityCard.inProgress.text')} ·{' '}
        <span
          className={clsx('inline-flex ml-1 items-center', {
            'w-[33px]': !hasHours,
            'w-[40px]': hasHours,
          })}
        >
          {timerString}
        </span>
      </GBoxedInfo>
    );
  }

  return (
    <GBoxedInfo className="rounded-full px-3 py-0 min-h-[26px] font-medium flex items-center">
      <div className="flex items-center space-x-1">
        <IconWrapper className="w-[14px] h-[14px]" icon={ClockIcon} />
        <div>
          {format(course.originalStartAtDate, withDate ? 'MMM dd, HH:mm' : 'HH:mm')} -{' '}
          {format(course.originalEndAtDate, 'HH:mm')}
        </div>
      </div>
    </GBoxedInfo>
  );
};

export const useBusinessMember = (userId: string) => {
  const { businessUuid } = useBusiness();
  const { data: user, isFetching } = useQuery(
    ['businessMember', userId, businessUuid],
    () => {
      if (businessUuid) return getBusinessMember(businessUuid, userId);

      return Promise.resolve(null);
    },
    {
      staleTime: Infinity,
    },
  );

  return { user, isFetching };
};

export const SkeletonLoading = ({ className }: { className?: string }) => (
  <div className={twMerge('animate-pulse bg-gray-50 rounded-md', className)} />
);

export const CourseCardSkeleton = () => {
  return (
    <motion.div className="p-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="flex flex-row space-x-4 items-center justify-between">
        <div className="flex flex-col space-y-1 flex-1">
          <div className="flex flex-col h-6 justify-center">
            <SkeletonLoading className="h-4 w-[30%] md:w-[10%] " />
          </div>
          <div className="flex flex-col h-5 justify-center mg:flex-row space-y-1 md:space-x-1 md:space-y-0">
            <SkeletonLoading className="h-4 w-[50%] md:w-[30%] " />
          </div>
          <div className="flex flex-col h-5 justify-center mg:flex-row space-y-1 md:space-x-1 md:space-y-0">
            <SkeletonLoading className="h-4 w-[60%] md:w-[40%] " />
          </div>
        </div>
        <SkeletonLoading className="w-20 h-20 rounded-xl" />
      </div>
    </motion.div>
  );
};
