import { useAuth } from '@/auth/hooks/use-auth';
import { User } from '@/auth/types';
import { PageWrapper } from '@/common/components/page-wrapper';
import { useSnack } from '@/common/hooks/use-snack';
import { GAvatar } from '@/design-system/g-avatar';
import { GLoader } from '@/design-system/g-loader';
import Button from '@/design-system/v3/button';
import { checkInMock, getBusinessCheckinInfo, getQRCode } from '@/web/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { useQuery, useQueryClient } from 'react-query';
import GymlySymbol from '@/gymly-symbol.svg?react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { appState, panelState } from '@/common/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CheckinFailureReasons, CheckinFailureReasonType, CheckinUsageType } from '@/web/types';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { getProfilePath, INVOICES_PATH, SHOP_PATH } from '@/web/routes';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import NfcIcon from '@/common/components/icon/icons/v2/nfc.svg?react';
import CheckCircleIcon from '@/common/components/icon/icons/v2/check-circle.svg?react';
import { format, parse, parseISO } from 'date-fns';
import { CourseCard } from '@/common/components/booking-flow/course-card';
import { addHelpersToCourse } from '@/web/utils';
import { useEmbed } from '@/common/hooks/use-embed';
import { useBusinessMemberships } from '@/web/hooks/use-business-memberships';

function getFullName(user: User | null): string | null {
  if (!user) return null;

  return [user.firstName, user.insertion, user.lastName].filter(Boolean).join(' ');
}

export const FETCH_BUSINESS_CHECKIN_INFO = 'FETCH_BUSINESS_CHECKIN_INFO';

const SUPPORTED_FAILURE_REASONS = [
  CheckinFailureReasonType.INVALID_MEMBERSHIP,
  CheckinFailureReasonType.INVOICE_DUE,
  CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION,
  CheckinFailureReasonType.INVALID_TIME_RANGE,
  CheckinFailureReasonType.TIME_OUT,
];

const QR_CODE_FETCH = 'QR_CODE_FETCH';

export const QRCodeView = () => {
  const { t } = useTranslation();
  const { successMessage, errorMessage } = useSnack();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { businessUuid, primaryLocationId, businessSettings } = useBusiness();
  const { foreground } = useRecoilValue(appState);
  const setPanel = useSetRecoilState(panelState);
  const embed = useEmbed();

  const {
    data: qrBlob,
    isLoading,
    isFetching,
  } = useQuery([QR_CODE_FETCH, foreground], async () => {
    return getQRCode(businessUuid as string);
  });

  const handleRefresh = useCallback(async () => {
    return queryClient.invalidateQueries([QR_CODE_FETCH]);
  }, [queryClient]);

  useQuery(
    [FETCH_BUSINESS_CHECKIN_INFO, businessUuid],
    async () => {
      const checkinInfo = await getBusinessCheckinInfo(businessUuid as string);

      const failureReason = checkinInfo.failureReasons.find((reason) =>
        SUPPORTED_FAILURE_REASONS.includes(reason.type),
      );

      if (!failureReason) {
        return;
      }

      triggerHapticFeedback(HapticFeedbackType.NOTIFICATION_ERROR);
      setPanel({
        component: CheckinWarningModal,
        isOpen: true,
        autoHeight: true,
        props: {
          failureReason,
        },
      });
    },
    {
      refetchInterval: 4000,
    },
  );

  useEffect(() => {
    window['ReactNativeWebView']?.postMessage?.(JSON.stringify({ type: 'SET_BRIGHTNESS', payload: 1 }));

    return () => {
      window['ReactNativeWebView']?.postMessage?.(JSON.stringify({ type: 'RESET_BRIGHTNESS' }));
    };
  }, []);

  const content = (
    <>
      <div className="h-contentHeight">
        <div className="flex flex-col h-full items-center">
          <div className="w-full mt-5">
            <PullToRefresh onRefresh={handleRefresh}>
              <div className="rounded-xl w-full overflow-hidden">
                <div className="bg-white pb-4 pt-6 -m-[1px]">
                  <div className="flex justify-center items-center mb-8">
                    <div
                      style={{ width: '134px', height: '17px' }}
                      className="bg-gray-50 shadow-inner rounded-full"
                    ></div>
                  </div>
                  <div className="flex flex-col space-y-3">
                    <div className="flex flex-col items-center space-y-4">
                      <div className="w-[80px] h-[80px] relative">
                        <GAvatar path={user?.profileImagePath} size="2xl" letter={user?.firstName?.charAt(0)} />
                        <div
                          className="flex flex-row items-center gap-[2px] right-0 -bottom-2 z-[1] h-[22px] w-[38px] absolute py-1 px-[6px] bg-black-100 text-white rounded-full shadow-2xl"
                          style={{
                            filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))',
                          }}
                        >
                          <GymlySymbol />
                          <span className="text-xs leading-[14px] font-median">id</span>
                        </div>
                      </div>
                      <span className="text-lg font-semibold text-black-100">{getFullName(user)}</span>
                    </div>
                  </div>
                  {businessSettings?.checkinUsageType === CheckinUsageType.ALL && (
                    <div className="flex flex-col items-center">
                      {isLoading || isFetching ? (
                        <div className="flex h-[325px] w-[325px] rounded-xl my-4 justify-center items-center border-gray-200 border">
                          <GLoader variant="secondary" />
                        </div>
                      ) : (
                        <GAvatar src={qrBlob} className="w-full max-w-[325px] h-[325px] rounded-none" />
                      )}
                    </div>
                  )}
                  {businessSettings?.checkinUsageType === CheckinUsageType.NFC_ONLY && (
                    <div className="flex flex-col items-center">
                      <div className="flex h-[215px] w-[325px] rounded-xl my-4 justify-center items-center bg-gray-50">
                        <div className="flex flex-col space-y-2 items-center justify-center text-center">
                          <div className="text-primary">
                            <NfcIcon />
                          </div>
                          <div className="font-neutral">{t('useRfidHint')}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </PullToRefresh>
            {!embed && businessSettings?.checkinUsageType === CheckinUsageType.ALL && (
              <div className="flex flex-col items-center py-8 justify-center">
                <div className="text-typo-secondary contrast:text-contrast-typo-secondary text-sm mx-4 text-center">
                  {t('qrCodeView.message')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {(window.isStaging || window.isDev) && (
        <Button
          variant="primary"
          onClick={async () => {
            try {
              await checkInMock(businessUuid as string, primaryLocationId as string);
              successMessage('Check in successful', {
                detailedMessage: 'You have successfully checked in to the gym.',
              });
            } catch (e) {
              errorMessage('Check in failed', {
                detailedMessage:
                  (e as Error).message ||
                  'Oops! An unexpected error has occurred. Please try again later or contact support for assistance.',
              });
            }
          }}
        >
          Check in
        </Button>
      )}
    </>
  );
  return <PageWrapper className="bg-gray-50" content={content} />;
};

export const CheckinWarningModal = ({ failureReason }: { failureReason: CheckinFailureReasons }) => {
  const { t } = useTranslation();
  const { businessActivitiesMap } = useBusiness();
  const { hasPurchasableMemberships } = useBusinessMemberships();
  const navigate = useNavigate();
  const setPanel = useSetRecoilState(panelState);
  const embed = useEmbed();

  const details = useMemo(() => {
    if (!businessActivitiesMap) {
      return;
    }

    if (
      failureReason.type === CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION &&
      failureReason.data.upcomingEvent &&
      failureReason.data.checkinTime
    ) {
      const event = failureReason.data.upcomingEvent;

      return (
        <div className="my-1 w-full">
          <div className="flex items-start text-left">
            <CourseCard
              className="px-0 w-full"
              course={addHelpersToCourse(event, businessActivitiesMap)}
              withTeachers={false}
              withJoinStatus={false}
              withSpots
            />
          </div>

          <div>
            {t('checkinWarning.noActivitySubscription.checkinTime.message', {
              time: format(parseISO(failureReason.data.checkinTime), 'HH:mm'),
            })}
          </div>
        </div>
      );
    }

    if (
      failureReason.type === CheckinFailureReasonType.INVALID_TIME_RANGE &&
      failureReason.data.validSlots.length > 0
    ) {
      return (
        <div>
          <div>{t('checkinWarning.invalidTimeRange.validSlots')}</div>
          <ul>
            {failureReason.data.validSlots.map(({ id, lowTime, upperTime }) => (
              <li key={id}>
                {format(parse(lowTime, 'HH:mm:ss', new Date()), 'HH:mm')} -{' '}
                {format(parse(upperTime, 'HH:mm:ss', new Date()), 'HH:mm')}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }, [t, failureReason, businessActivitiesMap]);

  return (
    <div className="flex flex-col items-center justify-center text-center text-base space-y-4">
      {failureReason.type === CheckinFailureReasonType.TIME_OUT && (
        <CheckCircleIcon className="text-typo-positive contrast:text-contrast-typo-positive w-12 h-12" />
      )}
      {failureReason.type !== CheckinFailureReasonType.TIME_OUT && (
        <ExclamationTriangleIcon className="text-typo-negative contrast:text-contrast-typo-negativ w-12 h-12" />
      )}
      <div className="flex flex-col space-y-1">
        <span className="font-semibold text-typo-primary contrast:text-contrast-typo-primary">
          {failureReason.type === CheckinFailureReasonType.INVALID_MEMBERSHIP &&
            t('checkinWarning.invalidMembership.title')}
          {failureReason.type === CheckinFailureReasonType.INVOICE_DUE && t('checkinWarning.invoiceDue.title')}
          {failureReason.type === CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION &&
            !failureReason.data.upcomingEvent &&
            t('checkinWarning.noActivitySubscription.title')}
          {failureReason.type === CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION &&
            failureReason.data.upcomingEvent &&
            t('checkinWarning.noActivitySubscription.tooEarly.title')}
          {failureReason.type === CheckinFailureReasonType.INVALID_TIME_RANGE &&
            t('checkinWarning.invalidTimeRange.title')}
          {failureReason.type === CheckinFailureReasonType.TIME_OUT && t('checkinWarning.timeOut.title')}
        </span>
        <span className="text-gray-600">
          {failureReason.type === CheckinFailureReasonType.INVALID_MEMBERSHIP &&
            t('checkinWarning.invalidMembership.message')}
          {failureReason.type === CheckinFailureReasonType.INVOICE_DUE && t('checkinWarning.invoiceDue.message')}
          {failureReason.type === CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION &&
            !failureReason.data.upcomingEvent &&
            t('checkinWarning.noActivitySubscription.message')}
          {failureReason.type === CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION &&
            failureReason.data.upcomingEvent &&
            t('checkinWarning.noActivitySubscription.tooEarly.message')}
          {failureReason.type === CheckinFailureReasonType.INVALID_TIME_RANGE &&
            t('checkinWarning.invalidTimeRange.message')}
          {failureReason.type === CheckinFailureReasonType.TIME_OUT &&
            t('checkinWarning.timeOut.message', {
              time: format(parseISO(failureReason.data.expiresAt), 'HH:mm'),
            })}
        </span>
      </div>

      {details && <div className="px-1 w-full">{details}</div>}

      {!embed && (
        <div className="w-full">
          {failureReason.type === CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION &&
            !failureReason.data.upcomingEvent && (
              <Button
                variant="primary"
                onClick={() => {
                  setPanel(null);
                  navigate('/');
                }}
              >
                {t('checkinWarning.action.subscribe')}
              </Button>
            )}
          {failureReason.type === CheckinFailureReasonType.INVOICE_DUE && (
            <Button
              variant="primary"
              onClick={() => {
                setPanel(null);
                navigate(getProfilePath(INVOICES_PATH));
              }}
            >
              {t('checkinWarning.action.pay')}
            </Button>
          )}
          {hasPurchasableMemberships &&
            (failureReason.type === CheckinFailureReasonType.INVALID_MEMBERSHIP ||
              failureReason.type === CheckinFailureReasonType.INVALID_TIME_RANGE) && (
              <Button
                variant="primary"
                onClick={() => {
                  setPanel(null);
                  navigate(SHOP_PATH);
                }}
              >
                {t('checkinWarning.action.viewMemberships')}
              </Button>
            )}
        </div>
      )}
    </div>
  );
};
