import SplitScreenImageLayout from '@/common/layouts/SplitScreenImageLayout';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { getCourseTypes, getEvent } from '@/web/endpoints';
import { CourseType } from '@/web/types';
import { GPageLoader } from '@/design-system/v3/g-page-loader';
import { CardWithTitle } from '@/common/components/card-with-title/CardWithTitle';
import { formatWithT } from '@/i18n/utils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MarkerPinIcon from '@/icons/marker-pin.svg?react';
import ClockIcon from '@/icons/clock.svg?react';
import { handleValidationError, parseCourseISO } from '@/common/utils';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import Button from '@/design-system/v3/button';
import { useSetRecoilState } from 'recoil';
import { popoverModalState } from '@/common/atoms';
import { cancelGuestBooking, getGuest } from '@/features/guest/api/GuestApi';
import { useSnack } from '@/common/hooks/use-snack';
import { GuestMemberStatus } from '@/features/guest/enums/GuestMemberStatus.enum';

const FETCH_GUEST_DETAIL = 'FETCH_GUEST_DETAIL';
const FETCH_GUEST_BOOKING_EVENT = 'FETCH_GUEST_BOOKING_EVENT';

const ACTIVE_STATUSES = [GuestMemberStatus.ACTIVE, GuestMemberStatus.ATTENDED];
const CANCELLED_STATUSES = [GuestMemberStatus.CANCELLED, GuestMemberStatus.LATE_CANCELLED];

export const ManageGuestBookingView = () => {
  const { t } = useTranslation();
  const { businessId, eventId, guestId } = useParams<{
    businessId: string;
    eventId: string;
    guestId: string;
  }>();
  const setPopoverModal = useSetRecoilState(popoverModalState);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { data: guest, isLoading } = useQuery([FETCH_GUEST_DETAIL, businessId, eventId, guestId, token], async () => {
    if (!businessId || !eventId || !token || !guestId) {
      return null;
    }

    return getGuest(businessId, eventId, guestId, token);
  });

  const { data: event, isLoading: isLoadingEvent } = useQuery(
    [FETCH_GUEST_BOOKING_EVENT, businessId, eventId, guestId, token],
    async () => {
      if (!businessId || !eventId) {
        return null;
      }

      const activityMap = (await getCourseTypes(businessId)).content.reduce(
        (acc, type) => {
          acc[type.id] = type;
          return acc;
        },
        {} as Record<string, CourseType>,
      );

      return getEvent(businessId, eventId, activityMap);
    },
  );

  if (!event || isLoadingEvent || !guest || isLoading) {
    return <GPageLoader />;
  }

  const startAt = parseCourseISO(event.startAt, event);
  const endsAt = parseCourseISO(event.endAt, event);

  const isFutureEvent = startAt > new Date();

  const handleCancelClick = () => {
    const token = searchParams.get('token');

    setPopoverModal({
      title: t('activityActions.cancel.title'),
      component: CancelGuestBookingModal,
      props: {
        businessId,
        eventId,
        guestId,
        token,
      },
    });
  };

  return (
    <SplitScreenImageLayout imagePath={event.detailedActivities[0].imagePath} locationId={event.businessLocation.id}>
      <div className="py-8 md:py-16">
        <div>
          <div className="flex flex-col space-y-4">
            <h2 className="font-median text-2xl font-semibold">{t('guest.manageBooking.title')}</h2>
            <div className="flex flex-row space-x-1">
              {ACTIVE_STATUSES.includes(guest.status) && (
                <>
                  <CheckCircleIcon className="w-6 h-6 text-typo-positive" />
                  <div className="text-base">{t('guest.manageBooking.statusActive')}</div>
                </>
              )}
              {CANCELLED_STATUSES.includes(guest.status) && (
                <>
                  <XCircleIcon className="w-6 h-6 text-typo-negative" />
                  <div className="text-base">{t('guest.manageBooking.statusCancelled')}</div>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col space-y-5 mt-6">
            <CardWithTitle title="">
              <div className="divide-y">
                <div className="p-5 space-y-1">
                  <h3 className="text-xl font-semibold">{event.name}</h3>
                  {event.teachers.length > 0 && <p>{event.teachers.map((t) => t.fullName).join(', ')}</p>}
                </div>

                <div className="p-5">
                  <p className="text-typo-tertiary text-sm">{t('activityDetails.details.title')}</p>
                  <h3 className="text-xl font-semibold">{formatWithT(startAt, 'EEEE, d MMMM yyyy')}</h3>
                  <div>
                    <div className="flex items-center">
                      <MarkerPinIcon className="w-5 h-5" /> <span className="ms-1">{event.businessLocation.name}</span>
                    </div>
                    <div className="flex items-center">
                      <ClockIcon className="w-5 h-5" />
                      <span className="ms-1">
                        {format(startAt, 'HH:mm')} - {format(endsAt, 'HH:mm')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </CardWithTitle>

            {ACTIVE_STATUSES.includes(guest.status) && (
              <Button
                variant="secondary"
                className="text-typo-negative contrast:text-contrast-typo-negative"
                onClick={handleCancelClick}
                disabled={!isFutureEvent}
              >
                {t('cancel')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </SplitScreenImageLayout>
  );
};

const CancelGuestBookingModal = ({
  businessId,
  eventId,
  guestId,
  token,
  handleClose,
}: {
  businessId: string;
  eventId: string;
  guestId: string;
  token: string;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const { errorMessage } = useSnack();
  const queryClient = useQueryClient();

  const doCancel = async () => {
    try {
      await cancelGuestBooking(businessId, eventId, guestId, token);
      await queryClient.invalidateQueries(FETCH_GUEST_DETAIL);
      handleClose();
    } catch (e) {
      handleValidationError(e, undefined, errorMessage);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="text-base">{t('activityActions.cancel.message')}</div>
      <div className="flex flex-col space-y-2">
        <Button variant="primary" onClick={doCancel}>
          {t('confirm')}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};
