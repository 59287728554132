import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '@/web/views/extra-menu';
import { getManagePath, MANAGE_COURSES_PATH } from '@/web/routes';
import Button from '@/design-system/v3/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import { getCourseType, updateCourseType } from '@/web/endpoints';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { getNameValidator } from '@/common/validators';
import { handleValidationError } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import { GInput } from '@/design-system/v3/g-input';
import { FETCH_COURSE_TYPES } from '@/web/views/management/manage-courses-view';
import { Textarea } from '@/design-system/v3/textarea';
import { useTranslation } from 'react-i18next';

export const FETCH_COURSE_TYPE = 'FETCH_COURSE_TYPE';

type ManageCourseForm = {
  name: string;
  description: string;
};

export const ManageCourseView = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { successMessage, errorMessage } = useSnack();

  const { data, isLoading } = useQuery([FETCH_COURSE_TYPE, businessUuid, id], () => {
    if (businessUuid && id) {
      return getCourseType(businessUuid, id);
    }
  });

  const defaultValues = useMemo<ManageCourseForm>(
    () => ({
      name: data?.name || '',
      description: data?.description || '',
    }),
    [data],
  );

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ManageCourseForm>({
    defaultValues,
  });

  const doSubmit = async (data: ManageCourseForm) => {
    if (!businessUuid || !id) {
      return;
    }

    try {
      await updateCourseType(businessUuid, id, data);
    } catch (e) {
      return handleValidationError(e, setError, errorMessage);
    }

    try {
      await Promise.all([
        queryClient.invalidateQueries([FETCH_COURSE_TYPES, businessUuid]),
        queryClient.invalidateQueries([FETCH_COURSE_TYPE, businessUuid, id]),
      ]);
    } finally {
      successMessage('saved');
      navigate(getManagePath(MANAGE_COURSES_PATH));
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return (
    <PageWrapper
      header={
        <PageHeader
          title={data?.name}
          leftAction={<BackToButton path={getManagePath(MANAGE_COURSES_PATH)} />}
          rightAction={
            <Button
              variant="tertiary"
              gsize="small"
              onClick={handleSubmit(doSubmit)}
              disabled={isLoading || isSubmitting}
            >
              {t('save')}
            </Button>
          }
        />
      }
      content={
        <div className="mt-4 space-y-5">
          <GInput
            {...control.register('name', {
              ...getNameValidator(),
            })}
            title={t('activityType.name')}
            error={errors.name?.message?.toString()}
            placeholder="Name"
            disabled={isLoading}
            autoFocus
          />
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textarea
                placeholder={t('activityType.descriptionPlaceholder')}
                className="min-h-[160px]"
                onChange={onChange}
                value={value}
                disabled={isLoading}
                initialValue={data?.description || undefined}
              />
            )}
          />
        </div>
      }
    />
  );
};
